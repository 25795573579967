.dashboard {
  padding: 20px 0;
  color: #cccccc;
  min-height: 100vh;
  position: relative;
}

.notification-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
}

.notification-content {
  background: rgba(30, 30, 30, 0.95);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 20px 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
  color: #fff;
}

.notification-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(180deg, #06189c 0%, rgba(6, 24, 156, 0.3) 100%);
}

.notification-content h3 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #fff;
}

.notification-content p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.9);
}

.close-notification {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(6, 24, 156, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.8);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.close-notification:hover {
  background: rgba(6, 24, 156, 0.5);
  color: #fff;
  transform: scale(1.05);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.close-notification:active {
  transform: scale(0.95);
}

.dashboard-header {
  text-align: center;
  margin-bottom: 40px;
  padding: 30px 20px;
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.4) 0%, rgba(10, 10, 42, 0.1) 100%);
  border-radius: 15px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.dashboard-header h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
}

.dashboard-header p {
  font-size: 1.2rem;
  opacity: 0.8;
  letter-spacing: 0.5px;
}

.fx-rates-container {
  margin-bottom: 50px;
}

.fx-rates-container h2 {
  font-size: 2rem;
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  padding: 12px 25px;
  background: linear-gradient(90deg, rgba(6, 24, 156, 0.5) 0%, rgba(6, 24, 156, 0) 100%);
  border-radius: 8px;
  border-left: 3px solid #06189c;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 10px rgba(6, 24, 156, 0.5);
  transform-origin: left;
  animation: sectionTitleFadeIn 1.5s ease forwards;
  display: block;
  position: relative;
  overflow: hidden;
}

.fx-rates-container h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: shimmer 4s infinite;
}

@keyframes sectionTitleFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.fx-rates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.fx-rate-card {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
}

.fx-rate-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(180deg, #06189c 0%, rgba(6, 24, 156, 0.3) 100%);
}

.fx-rate-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.fx-rate-card h3 {
  margin-bottom: 15px;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 400;
}

.fx-rate-card .rate {
  font-size: 2.2rem;
  font-weight: 300;
  margin-bottom: 15px;
  color: #fff;
  letter-spacing: 1px;
}

.fx-rate-card a {
  display: inline-block;
  color: #fff;
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.7) 0%, rgba(10, 43, 224, 0.7) 100%);
  padding: 8px 18px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(6, 24, 156, 0.3);
  margin-top: 10px;
  letter-spacing: 0.5px;
}

.fx-rate-card a:hover {
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.9) 0%, rgba(10, 43, 224, 0.9) 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(6, 24, 156, 0.5);
}

.volume-chart-container,
.currency-outlooks-container,
.performance-chart-container {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.volume-chart-container h2,
.currency-outlooks-container h2,
.performance-chart-container h2 {
  font-size: 2rem;
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  padding: 12px 25px;
  background: linear-gradient(90deg, rgba(6, 24, 156, 0.5) 0%, rgba(6, 24, 156, 0) 100%);
  border-radius: 8px;
  border-left: 3px solid #06189c;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 10px rgba(6, 24, 156, 0.5);
  transform-origin: left;
  animation: sectionTitleFadeIn 0.8s ease forwards;
  display: block;
  position: relative;
  overflow: hidden;
}

.volume-chart-container h2::before,
.currency-outlooks-container h2::before,
.performance-chart-container h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: shimmer 4s infinite;
}

.chart-wrapper {
  height: 400px;
  position: relative;
}

.chart-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(6, 24, 156, 0.05) 0%, rgba(10, 10, 42, 0) 70%);
  pointer-events: none;
}

.year-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 15px;
}

.year-selector button {
  background: rgba(30, 30, 30, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  padding: 8px 24px;
  border-radius: 20px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.year-selector button:hover {
  background: rgba(6, 24, 156, 0.5);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.year-selector button.active {
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.9) 0%, rgba(10, 43, 224, 0.9) 100%);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 6px 15px rgba(6, 24, 156, 0.4);
}

.performance-summary,
.volume-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 10px;
  padding: 15px 20px;
  background: rgba(30, 30, 30, 0.4);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.total-performance,
.volume-metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.total-performance .label,
.volume-metric .label {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
}

.total-performance .value,
.volume-metric .value {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.volume-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.total-performance .value.positive {
  background: linear-gradient(135deg, rgba(0, 210, 91, 0.8) 0%, rgba(76, 175, 80, 0.7) 100%);
  color: white;
  border: 1px solid rgba(0, 210, 91, 0.3);
}

.total-performance .value.negative {
  background: linear-gradient(135deg, rgba(255, 45, 85, 0.8) 0%, rgba(244, 67, 54, 0.7) 100%);
  color: white;
  border: 1px solid rgba(255, 45, 85, 0.3);
}

.volume-metric .value {
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.8) 0%, rgba(10, 43, 224, 0.7) 100%);
  color: white;
  border: 1px solid rgba(6, 24, 156, 0.3);
}

.outlook-update-info {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}

.outlooks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.outlook-card {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
  text-align: center;
}

.outlook-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(180deg, #06189c 0%, rgba(6, 24, 156, 0.3) 100%);
}

.outlook-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.outlook-card h3 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 15px;
  color: #fff;
}

.outlook-indicator {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.outlook-indicator.bullish {
  background: linear-gradient(135deg, rgba(0, 210, 91, 0.9) 0%, rgba(76, 175, 80, 0.8) 100%);
  color: white;
}

.outlook-indicator.bearish {
  background: linear-gradient(135deg, rgba(255, 45, 85, 0.9) 0%, rgba(244, 67, 54, 0.8) 100%);
  color: white;
}

.outlook-indicator.neutral {
  background: linear-gradient(135deg, rgba(255, 179, 0, 0.9) 0%, rgba(255, 152, 0, 0.8) 100%);
  color: white;
}

.outlook-card a {
  display: inline-block;
  color: #fff;
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.7) 0%, rgba(10, 43, 224, 0.7) 100%);
  padding: 8px 18px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(6, 24, 156, 0.3);
  font-size: 0.9rem;
}

.outlook-card a:hover {
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.9) 0%, rgba(10, 43, 224, 0.9) 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(6, 24, 156, 0.5);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.5px;
  position: relative;
}

.loading::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top-color: #06189c;
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(50% + 40px);
  left: calc(50% - 20px);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Media Queries */
@media (max-width: 768px) {
  .dashboard-header {
    padding: 20px 15px;
  }
  
  .dashboard-header h1 {
    font-size: 2rem;
  }
  
  .fx-rates-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .volume-chart-container,
  .currency-outlooks-container,
  .performance-chart-container {
    padding: 20px 15px;
  }
  
  .chart-wrapper {
    height: 300px;
    overflow-x: auto;
  }
  
  .year-selector {
    gap: 10px;
  }
  
  .year-selector button {
    padding: 6px 18px;
    font-size: 0.9rem;
  }
  
  .total-performance,
  .volume-metrics {
    flex-direction: column;
    gap: 15px;
  }
  
  .total-performance .label,
  .volume-metric .label {
    font-size: 1rem;
  }
  
  .total-performance .value,
  .volume-metric .value {
    font-size: 1.3rem;
    padding: 4px 12px;
  }
  
  .outlooks-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 15px;
  }
  
  .outlook-card h3 {
    font-size: 1.4rem;
  }
  
  .outlook-indicator {
    font-size: 1rem;
    padding: 6px 16px;
  }
}

@media (max-width: 480px) {
  .fx-rates-grid {
    grid-template-columns: 1fr;
  }
  
  .fx-rate-card .rate {
    font-size: 1.8rem;
  }
  
  .chart-wrapper {
    height: 250px;
  }
  
  .year-selector button {
    padding: 5px 15px;
    font-size: 0.85rem;
  }
  
  .total-performance .label,
  .volume-metric .label {
    font-size: 0.9rem;
  }
  
  .total-performance .value,
  .volume-metric .value {
    font-size: 1.1rem;
    padding: 3px 10px;
  }
  
  .notification-popup {
    width: 95%;
    top: 10px;
  }
  
  .notification-content {
    padding: 15px 20px;
  }
  
  .notification-content h3 {
    font-size: 1.1rem;
    margin-right: 20px;
  }
  
  .notification-content p {
    font-size: 0.9rem;
  }
  
  .outlooks-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .outlook-card {
    padding: 15px 10px;
  }
  
  .outlook-card h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  
  .outlook-indicator {
    font-size: 0.9rem;
    padding: 5px 12px;
    margin-bottom: 10px;
  }
  
  .outlook-card a {
    padding: 6px 14px;
    font-size: 0.8rem;
  }
}