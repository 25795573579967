/* Global styles */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Navigation */
.navbar {
  background: rgba(10, 10, 42, 0.7);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container h1 {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  position: relative;
  letter-spacing: 1px;
}

.logo-container h1:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 50%;
  height: 2px;
  background: linear-gradient(90deg, #06189c 0%, rgba(6, 24, 156, 0) 100%);
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 30px;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  letter-spacing: 0.5px;
  padding: 6px 10px;
  display: inline-block;
}

.nav-links a:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #06189c 0%, #0a2be0 100%);
  transition: width 0.2s ease-in-out;
}

.nav-links a:hover {
  color: #ffffff;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}

.nav-links a:hover:after {
  width: 100%;
}

/* Dropdown Menu */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown span {
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in-out;
  padding: 6px 10px;
  display: inline-block;
}

.dropdown span:after {
  content: '▼';
  font-size: 0.7rem;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

/* Add a hover bridge to connect the dropdown trigger to the content */
.dropdown::after {
  content: '';
  position: absolute;
  height: 15px;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 1;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: rgba(10, 10, 42, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  min-width: 160px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s ease, visibility 0.2s;
  pointer-events: none;
}

.dropdown-content a {
  color: #fff;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  text-align: center;
  transition: background 0.2s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-content a:last-child {
  border-bottom: none;
}

.dropdown-content a:hover {
  background: rgba(6, 24, 156, 0.3);
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  background: rgba(10, 10, 42, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin: 40px 0;
}

/* Footer */
.footer {
  background: rgba(10, 10, 42, 0.5);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 40px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.footer-content p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

/* Hamburger menu for mobile */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 101;
  position: relative;
}

.hamburger span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
  position: absolute;
  border-radius: 3px;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 9px;
}

.hamburger span:nth-child(3) {
  top: 18px;
}

.hamburger span.open:nth-child(1) {
  transform: rotate(45deg);
  top: 9px;
}

.hamburger span.open:nth-child(2) {
  opacity: 0;
}

.hamburger span.open:nth-child(3) {
  transform: rotate(-45deg);
  top: 9px;
}

/* Media Queries */
@media (max-width: 768px) {
  .nav-container {
    padding: 15px;
  }
  
  .hamburger {
    display: flex;
  }
  
  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background: rgba(10, 10, 42, 0.95);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 100;
    padding: 80px 0;
    box-shadow: -5px 0 30px rgba(0, 0, 0, 0.5);
  }
  
  .nav-links.active {
    right: 0;
  }
  
  .nav-links li {
    margin: 20px 0;
  }
  
  .dropdown-content {
    position: static;
    background: rgba(10, 10, 42, 0.5);
    transform: none;
    box-shadow: none;
    width: 100%;
    margin-top: 10px;
    border-left: 2px solid #06189c;
  }
  
  .dropdown:hover .dropdown-content {
    animation: none;
  }
  
  .logo-container h1 {
    font-size: 1.4rem;
  }
  
  main {
    padding: 0 15px;
  }
}