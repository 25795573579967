.about-page {
  padding: 20px 0;
  max-width: 1200px;
  margin: 0 auto;
  color: #cccccc;
}

.about-header {
  text-align: center;
  margin-bottom: 50px;
  padding: 40px 30px;
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.3) 0%, rgba(10, 10, 42, 0.1) 100%);
  border-radius: 15px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.about-header h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
}

.about-header p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.5px;
  font-weight: 300;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about-section {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #06189c 0%, rgba(6, 24, 156, 0) 100%);
  opacity: 0.7;
}

.about-section:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  padding: 12px 25px;
  background: linear-gradient(90deg, rgba(6, 24, 156, 0.5) 0%, rgba(6, 24, 156, 0) 100%);
  border-radius: 8px;
  border-left: 3px solid #06189c;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 10px rgba(6, 24, 156, 0.5);
  transform-origin: left;
  animation: sectionTitleFadeIn 1.5s ease forwards;
  display: block;
  position: relative;
  overflow: hidden;
}

.about-section h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: shimmer 4s infinite;
}

@keyframes sectionTitleFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.about-section p {
  margin-bottom: 20px;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3px;
  font-weight: 300;
}

.about-section strong {
  color: #fff;
  font-weight: 500;
}

.about-section p:last-child {
  margin-bottom: 0;
}

.currency-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.currency-card {
  background: rgba(30, 30, 30, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 25px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.currency-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #06189c 0%, rgba(10, 43, 224, 0.5) 100%);
}

.currency-card:hover {
  transform: translateY(-7px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.currency-card h3 {
  font-size: 1.6rem;
  margin-bottom: 8px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
}

.currency-card p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  letter-spacing: 0.3px;
  font-weight: 300;
}

.sources-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 30px;
}

.sources-list li {
  background: rgba(30, 30, 30, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 15px 20px;
  border-radius: 8px;
  position: relative;
  padding-left: 30px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3px;
  font-weight: 300;
}

.sources-list li:hover {
  transform: translateX(5px);
  background: rgba(30, 30, 30, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.sources-list li:before {
  content: '';
  width: 8px;
  height: 8px;
  background: linear-gradient(135deg, #06189c 0%, #0a2be0 100%);
  border-radius: 50%;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.analysis-list {
  list-style-type: none;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 25px;
}

.analysis-list li {
  background: rgba(30, 30, 30, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 15px 20px;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3px;
  font-weight: 300;
}

.analysis-list li:hover {
  background: rgba(30, 30, 30, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.analysis-list li strong {
  display: inline-block;
  margin-right: 5px;
  color: white;
  font-weight: 500;
}

@media (max-width: 768px) {
  .about-header {
    padding: 30px 20px;
  }
  
  .about-header h1 {
    font-size: 2rem;
  }
  
  .about-section {
    padding: 25px 20px;
  }
  
  .about-section h2 {
    font-size: 1.5rem;
  }
  
  .sources-list {
    grid-template-columns: 1fr;
  }
  
  .currency-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .currency-card h3 {
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .about-header h1 {
    font-size: 1.8rem;
  }
  
  .currency-grid {
    grid-template-columns: 1fr;
  }
}