/* Base reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #0a0a2a 0%, #121212 100%);
  background-attachment: fixed;
  background-size: cover;
  color: #cccccc;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 4px;
  border-radius: 3px;
}

a {
  color: #06189c;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

a:hover {
  color: #0a2be0;
  text-shadow: 0 0 8px rgba(6, 24, 156, 0.4);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

h1 {
  font-size: 2.5rem;
  letter-spacing: 1px;
}

h2 {
  font-size: 1.8rem;
  letter-spacing: 0.5px;
}

button, .button {
  background: linear-gradient(135deg, #06189c 0%, #0a2be0 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 15px rgba(6, 24, 156, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

button:hover, .button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(6, 24, 156, 0.5);
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, 0.4);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.6);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(120, 120, 120, 0.8);
}

/* Glass card styling */
.glass-card {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  padding: 25px;
  transition: all 0.2s ease-in-out;
}

.glass-card:hover {
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.15);
}

/* Gradient text */
.gradient-text {
  background: linear-gradient(135deg, #06189c 0%, #0a2be0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
}