.currency-page {
  padding: 20px 0;
  max-width: 1200px;
  margin: 0 auto;
  color: #cccccc;
}

.currency-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
  padding: 60px 40px;
  background: linear-gradient(180deg, rgba(6, 24, 156, 0.3) 0%, rgba(10, 10, 42, 0.1) 100%);
  border-radius: 18px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25);
  transform: translateZ(0);
}

.currency-header h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.currency-header h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  letter-spacing: 0.8px;
  position: relative;
  display: inline-block;
  padding: 0 10px;
}

.currency-header h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #06189c 0%, rgba(10, 43, 224, 0.5) 100%);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 24, 156, 0.3);
}

.rate-updated {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.5px;
  margin-top: 10px;
  font-style: italic;
}

.outlook-section,
.indicators-section,
.impact-factors-section,
.outlook-summary-section {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.outlook-section:hover,
.indicators-section:hover,
.impact-factors-section:hover,
.outlook-summary-section:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.outlook-section h2,
.indicators-section h2,
.speeches-section h2,
.events-section h2,
.impact-factors-section h2,
.outlook-summary-section h2 {
  font-size: 2rem;
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  padding: 12px 25px;
  background: linear-gradient(90deg, rgba(6, 24, 156, 0.5) 0%, rgba(6, 24, 156, 0) 100%);
  border-radius: 8px;
  border-left: 3px solid #06189c;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 10px rgba(6, 24, 156, 0.5);
  transform-origin: left;
  animation: sectionTitleFadeIn 1.5s ease forwards;
  display: block;
  position: relative;
  overflow: hidden;
}

.outlook-section h2::before,
.indicators-section h2::before,
.speeches-section h2::before,
.events-section h2::before,
.impact-factors-section h2::before,
.outlook-summary-section h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: shimmer 4s infinite;
}

@keyframes sectionTitleFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.outlook-content {
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3px;
  font-weight: 300;
}

.outlook-content p {
  margin-bottom: 20px;
}

.indicators-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto;
  display: block;
}

.indicators-table th,
.indicators-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  font-weight: 300;
}

.indicators-table th {
  background: rgba(6, 24, 156, 0.2);
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.indicators-table tr {
  transition: background 0.2s ease;
}

.indicators-table tr:nth-child(even) {
  background: rgba(255, 255, 255, 0.03);
}

.indicators-table tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

.currency-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 40px;
}

.speeches-section,
.events-section {
  background: rgba(30, 30, 30, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  height: 100%;
}

.speeches-section:hover,
.events-section:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.speeches-list,
.events-list {
  list-style: none;
}

.speech-item,
.event-item {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: transform 0.2s ease;
}

.speech-item:hover,
.event-item:hover {
  transform: translateX(5px);
}

.speech-item:last-child,
.event-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.speech-item h3,
.event-item h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.speech-date,
.event-date {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 12px;
  letter-spacing: 0.3px;
  font-weight: 300;
}

.speech-summary {
  margin-bottom: 15px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  letter-spacing: 0.3px;
}

.speech-item a,
.event-item a {
  display: inline-block;
  color: #fff;
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.6) 0%, rgba(10, 43, 224, 0.6) 100%);
  padding: 6px 15px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 15px rgba(6, 24, 156, 0.2);
  margin-top: 10px;
  font-size: 0.9rem;
  letter-spacing: 0.3px;
}

.speech-item a:hover,
.event-item a:hover {
  background: linear-gradient(135deg, rgba(6, 24, 156, 0.8) 0%, rgba(10, 43, 224, 0.8) 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(6, 24, 156, 0.3);
}

.impact-factors-list {
  padding-left: 25px;
  counter-reset: factor;
}

.impact-factor-item {
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  padding-left: 35px;
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.impact-factor-item strong {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  font-weight: 500;
}

.impact-factor-item::before {
  counter-increment: factor;
  content: counter(factor);
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(135deg, #06189c 0%, #0a2be0 100%);
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.outlook-badge {
  display: inline-block;
  padding: 12px 30px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 25px;
  letter-spacing: 0.8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
  transform: translateZ(0);
}

.outlook-badge:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3);
}

.outlook-badge.bullish {
  background: linear-gradient(135deg, rgba(0, 210, 91, 0.9) 0%, rgba(76, 175, 80, 0.8) 100%);
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.outlook-badge.bearish {
  background: linear-gradient(135deg, rgba(255, 45, 85, 0.9) 0%, rgba(244, 67, 54, 0.8) 100%);
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.outlook-badge.neutral {
  background: linear-gradient(135deg, rgba(255, 179, 0, 0.9) 0%, rgba(255, 152, 0, 0.8) 100%);
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Add shimmer effect to the badges */
.outlook-badge::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    transparent, 
    rgba(255, 255, 255, 0.2), 
    transparent
  );
  animation: shimmerEffect 3s infinite;
}

/* Shimmer animation only */

@keyframes shimmerEffect {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.outlook-justification {
  margin-bottom: 20px;
  background: rgba(30, 30, 30, 0.3);
  border-radius: 10px;
  padding: 15px 20px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.outlook-justification p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.outlook-disclaimer {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  letter-spacing: 0.3px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .currency-info-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .indicators-table {
    font-size: 0.9rem;
  }
  
  .indicators-table th,
  .indicators-table td {
    padding: 10px;
  }
  
  .currency-header {
    padding: 40px 20px;
  }
  
  .currency-header h1 {
    font-size: 2.8rem;
  }
  
  .currency-header h2 {
    font-size: 1.5rem;
  }
  
  /* Additional tablet styles */
  
  .outlook-section,
  .indicators-section,
  .speeches-section,
  .events-section,
  .impact-factors-section,
  .outlook-summary-section {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .currency-header h1 {
    font-size: 2.3rem;
  }
  
  .currency-header h2 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  
  .rate-updated {
    font-size: 0.9rem;
  }
  
  .outlook-badge {
    font-size: 1.1rem;
    padding: 10px 25px;
    margin-bottom: 20px;
  }
}

/* Loading and error states */
.loading, .error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 20px;
}

.loading::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top-color: #06189c;
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(50% + 60px);
  left: calc(50% - 20px);
}

.error-message {
  background: rgba(244, 67, 54, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(244, 67, 54, 0.3);
  border-radius: 10px;
  max-width: 600px;
  margin: 100px auto;
  padding: 30px;
  color: #f44336;
}